<template>
  <div>
    <h3>What is a Lens?</h3>
    <p>
      A lens is made of transperent material bounded by two spherical surfaces. The surfaces may be
      both convex or both concave or any other combination of these two. Lenses are of quite some use
      in our daily life, starting with our eyes. Spectacles also contain lenses that are used to correct
      some defects in the eyes. Apart from these widely known uses, lenses are of much help in making
      instruments like microscope, magnifying glass,telescopes and cameras. The most common types of lenses
      are the bi-convex and the bi-concave lenses which are simply referred to as convex and concave lenses
      respectively.
    </p>
    <h3>Christian Huygens and Issac Newton</h3>
    <p>
      We know that lenses refract light, but the development of theory of lenses was a part of the biggest
      dilemma of physics- a debate that lasted for nearly 400 years. The character of light was a complicated
      one. Even the great Issac Newton had made mistakes in his development of a theory on light. Newton was
      of the belief that light was made up of tiny particles. He could provide suitable explanations for all
      observable phenomenon using this theory. Christian Huygens, a contemporary of Newton, however, chose to differ. He believed that light was
      a wave, just like ripples on the water surface when something falls into it. He was also able to provide
      suitable explanations for all observable phenomenon at the time. The two differed when it came to the
      speed of light in media other than air. According to Newton, light traveled <i>faster</i> in other media
      and according to Huygens, light traveled <i>slower</i> in media other than air. Due to his authority(which
      was well founded, given his contributions to Mechanics), Newton had the upper hand and his theory was
      the widely accepted one. The debate didn't stop there, with Thomas Young, Albert Einstein, Max Planck
      contributing to either theory, it lasted a good 400 years. With de Brogile's work, the debated ended
      with everyone accepting the dual nature of light.
    </p>
    <p>
      The above history must confuse you with its conclusion- the dual nature of light. It was just as
      head-scratching to all of the Nobel Prize winning scientists as well. We will take up the discussion
      of Huygens' theory in Wave Optics and see the dual nature in Modern Physics. Those discussions should clear
      out any confusions but for the time being we restrict ourselves to the particle nature of light.
    </p>
    <h3>The Ray Diagrams</h3>
    <p>The following is a sample ray diagram:</p>
    <p><img src="Sample ray diagram.jpg" style="width: 50%;height: 50%;"> </p>
    <p>
      We will have a look at some technical terms related to lenses:
      <ol>
        <li>
          <b>Centre of Curvature(C):</b> The two surfaces of the lens are parts of two spheres. The centres
          of those spheres are called Centres of Curvature.
        </li>
        <li>
          <b>Radius of Curvature(R):</b>This is simply the radius of the sphere whose centre is the centre
          of curvature.
        </li>
        <li><b>Principal Axis(\(N_1N_2\)):</b>It is the line that joins the two centres of curvature</li>
        <li>
          <b>Optical Centre(O):</b>It is a point through which an incident ray emerges parallel to itself
          and if the lens is thin, the incident ray is undeviated.
        </li>
        <li>
          <b>First Principal Focus(\(F_1\)):</b>It is the location of the object for which the image formed
          through the lens is at infinity
        </li>
        <li>
          <b>Second Principal Focus(\(F_2\)):</b>It is the location of the image when the object is at
          infinity
        </li>
        <li><b>Focal length(f):</b>Distance between the focus and the optical center</li>
        <li>
          <b>Focal plane:</b>The plane normal to the principal axis, passing through the focus is called
          focal plane
        </li>
      </ol>
    </p>
    <h3>The Sign Convention</h3>
    <p>
      When an object is placed before a lens, an image is formed. There is a sign convention that needs to be
      kept in mind before using any formula to calculate the position and the characteristics of an image.
      <ol>
        <li>
          Any measurement in the direction of light propagation will be taken positive, and any
          measurement opposite to direction of light propagation will be negative
        </li>
        <li>
          Any measurement in the upward direction with respect to the principal axis is taken positive
          and any measurement in the downward direction is taken to be negative.
        </li>
      </ol>
    </p>
    <h3>The Lens Formula</h3>
    <p>
      Now lets have a look at the lens formula for thin lenses:
      $$\frac{1}{u}+\frac{1}{v}=\frac{1}{f} $$
      where,
      \(u\) is the object distance,
      \(v\) is the image distance,
      and  \(f\) is the focal length.
      Note that while using the above formula, the input values for \(u\), \(v\) and \(f\) must be substituted
      <b>with</b> their respective signs, which are decided according to the sign convention.
    </p>
    <h3>Magnification</h3>
    <p>
      The magnification for a lens is defined as:
      $$m=\frac{v}{u}$$
      where the values of \(v\) and \(u\) must be substituted with their respective signs.
    </p>
    <h3>Converging and Diverging Lenses</h3>
    <p>A lens is called <i>Converging</i> if: \(f>0\) and it is called <i>Diverging</i> if: \(f<0\) </p>
    <h3>Power</h3>
    <p>
      The Power(\(P\)) of a lens of focal length \(f\) is given by:
      $$P=\frac{n_s}{f}$$
      where,
      \(n_s\) is the refractive index of the medium surrounding the lens.
      The unit of Power of a lens is Dioptre(D) if the focal length <i><b>is in metres</b></i>
    </p>
    <p>
      With the Power of lenses, we can obtain the equivalent power for a combination of lenses or even lenses
      and mirrors using the <i>Principle of Superposition</i>
      $$P_{eq}=\sum_{all\, mirrors\, and\, lenses}P_i  $$
      If we have a combination of lenses, we can also get the equivalent focal length using the above formula
      $$P_{eq}=\frac{1}{f_{eq}}=\sum_{i=1}^m \frac{1}{f_i} $$
      (if there are m lenses).
    </p>

    <h3>Nature of image formed:</h3>
    <p>
      You can find out the position and size of the images formed by the lenses for different positions of the
      object using this fun MagicGraph.
    </p>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Lens',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Lens Theory';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a lens?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Ray Diagrams', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Lens Formula', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Lens Theory',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to use ray diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
