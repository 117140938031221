import {
  makeResponsive,
  //makeResponsive(brd1);
  placeTitle,
  //placeTitle(brd1,'title', 'subtitle');
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  //placeArrow(board, pt1, pt2, width, cols)
  placeGravity,
  placeText,
  //placeText(board, positionX, positionY, message)
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeSliderSwitch,
  placeBoldText,
  //placeBoldText(brd6, -9, 1, 'Liquid');
  placeDash,
  // placeDash(board, [x1, y1], [x2, y2], width, cols)
  placeRightText,
  placeLabel
} from '../../../common/edliy_utils-lens';
const Boxes = {
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-8, 10, 8, -6],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
   //suspend updates
    brd1.suspendUpdate();
    // Layering
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    //Set CSS
    brd1.options.text.cssDefaultStyle = 'fontFamily:Oswald;'
    brd1.options.text.highlightCssDefaultStyle = 'fontFamily:Oswald';
    //Set Highlight
    brd1.options.text.highlight = false;
    brd1.options.line.highlight=false;
    brd1.options.arrow.highlight=false;
    brd1.options.point.highlight=false;
    brd1.options.arc.highlight=false;
//   Fixing
    brd1.options.text.fixed=true;
//    JXG.Options.point.fixed=true;
    brd1.options.layer['image']=17;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=14;
    brd1.options.layer['point']=15;
    brd1.options.layer['glider']=15;
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeTitle(brd1, 'Convex Lens', '1. Set the Focal Length 2. Place the Object 3. Observe the Image');
    //dragMe(brd1, 5, 5);
    //var xaxis = brd1.create('line',[[0,0], [0.5, 0]],{visible:false});
    var xaxis = placeLine(brd1, [-15,0],[+15, 0], 1, 'grey');
    xaxis.setAttribute({dash:1})
    //
    var player =placeImage(brd1, '/assets/candle.svg', -4, 0, 1, 0);
    player.setAttribute({fixed:false});
    hoverMe(brd1, player, 'Object', 5, -15);
    brd1.on('move', function(){player.moveTo([player.X(), 0.])});
    var trackP = brd1.create('glider', [()=>player.X()+0.5, 0, xaxis], {size:0, name:'', face:'circle', strokeColor:'black', fillColor:'white', label:{fontSize:20}});
//
    var glid = brd1.create('glider', [2, 0, xaxis], {size:1, name:'', face:'circle', strokeColor:'black', fillColor:'black', label:{fontSize:20}});
    placeLabel(brd1, glid, 'F_2', 0, -15);
    var focus2 = placePoint(brd1, -2, 0, 1, 'black', 'black');
    placeLabel(brd1, focus2, 'F_1', 0, -15);
    var TopPt = placePoint(brd1, 0, 1.75, 0, 'white', 'black');
    var BotPt = placePoint(brd1, 0, -1.75, 0, 'white', 'black');
    placeDash(brd1, [0, -1.75],[0, 1.75], 1, 'black');
    //brd1.create('point', [0, -1.75],{size:0, name:''});
    var se =brd1.create('line', [TopPt, BotPt], {visible:false});
    var front =brd1.create('arc',[glid, TopPt, BotPt],{strokeColor:'grey',
    strokeWidth:2, fillColor:'#C7D7E5'});
    var vert =brd1.create('line',[[0,0],[0,1]],{visible:false});
    var transf = brd1.create('transform', [se], {type:'reflect'});
//var ref1 = brd1.create('point', [TopPt, transf],{visible:false});
//var ref2 = brd1.create('point', [BotPt, transf], {visible:false});
    var ref = brd1.create('point', [glid, transf], {visible:false});
    var reat =brd1.create('arc',[ref, BotPt, TopPt],{strokeColor:'grey', strokeWidth:2, fillColor:'#C7D7E5'});
    //var track = brd1.create('line',[[0,1], [0.5, 1]],{visible:false});
    //var trackP = brd1.create('glider', [-4, 1, track], {size:5, name:'A', face:'diamond', strokeColor:'black'});
    //var trackb =brd1.create('point', [function(){return trackP.X()}, 0],{name:'B', size:3.5, face:'square',strokeColor:'black'});
    //var obj =brd1.create('perpendicularsegment', [trackP, xaxis], {strokeWidth:4, name:'B', strokeColor:'green'});
    var ray = placeArrow(brd1, [()=>trackP.X(), 1], [0, 1], 1,'blue');
    placeArrow(brd1, [()=>trackP.X(), 1], [0, 0], 1,'blue');
//    var ray =brd1.create('line', [[function(){return trackP.X()}, 1], [0, 1]], {strokeWidth:1, dash:1, visible:false});
    var inter =brd1.create('intersection', [vert, ray],{size:0, name:'', face:'square'});
    //var rayy =brd1.create('segment', [trackP, inter], {visible:true,strokeWidth:1, dash:1});
    placeArrow(brd1, [()=>inter.X(), 1], [()=>glid.X(), 0], 1,'blue');
    var transs = placeLine(brd1, [()=>inter.X(), 1], [()=>glid.X(), 0], 1,'blue');
//
    var tran =brd1.create('line', [inter, glid], {strokeWidth:1, dash:1, visible:function(){if(trackP.X()>glid.X()){return true}else{return false}}});
    var ray2 =brd1.create('line', [[()=>trackP.X(), 1], [0,0]],{strokeWidth:1, visible:false});
    //brd1.create('line', [[()=>trackP.X(), 1], [0,0]],{strokeWidth:1, dash:1, visible:function(){if(trackP.X()>-glid.X()){return true}else{return false}}});
    var ints =brd1.create('intersection',[tran, ray2],{size:0, name:'', face:'diamond', strokeColor:'black'});
    var imagee = brd1.create('image', ['/assets/candle.svg', [()=>ints.X()-0.5*ints.Y(), 0], [()=>ints.Y(), ()=>ints.Y()]],
    {fillOpacity:function(){if(ints.Y()>0){return 0.25}else{return 1}}, rotate:function(){if(ints.Y()>0){return 0} else{return 180}}});
    hoverMe(brd1, imagee, 'Image', 5, -15);
    var backt= brd1.create('segment', [ints, inter], {strokeWidth:1,dash:function(){if(trackP.X()<=-glid.X()){return 0} else{return 1}}});
     brd1.create('segment', [ints, [()=>trackP.X(), 1]], {strokeWidth:1, dash:function(){if(trackP.X()<=-glid.X()){return 0} else{return 1}}});
    //var perp =brd1.create('perpendicularpoint', [ints, xaxis], {strokeColor:'black', size:0, face:'square', name:'B\''});
    //var img =placeArrow(brd1, [()=>ints.X()+1, ()=>ints.Y()], [()=>ints.X()+1, 0], 1, 'black');
  //  img.setAttribute({firstArrow:true})
    //var img =brd1.create('perpendicularsegment', [ints, xaxis], {strokeColor:'green', strokeWidth:4});
    //var imgsize = brd1.create('text', [3, 5, function(){return ints.Y().toFixed(2)}],{anchorX:'middle',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, parse:true})
    //brd1.create('text',[3, 6, 'Image Size (A\'B\')'],{anchorX:'middle', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, parse:true});
    //brd1.create('text',[-6, 6, 'Object Size (AB)'],{anchorX:'middle',  fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, parse:true});
    placeLeftText(brd1, 4.25, 6,  function(){return ints.Y().toFixed(2)+' mm'});
    placeRightText(brd1, 4, 6, 'Image Size (A\'B\') :');
    placeLeftText(brd1, -6, 6, 'Object Size (AB):');
    placeLeftText(brd1,-2.75, 6, '1 mm');
    placeLeftText(brd1, -6, 5, 'Object Location:');
    placeLeftText(brd1,-2.75, 5, function(){return (trackP.X()).toFixed(2)+' mm'});
    placeRightText(brd1, 4, 5, 'Image Location :');
    placeLeftText(brd1, 4.25, 5, function(){return (ints.X()).toFixed(2) + ' mm'});
    placeRightText(brd1, 4, 4, 'Nature of Image :');

    placeLeftText(brd1, 4.25, 4, function(){if(ints.X()>0){return 'Real & Inverted'} else{return 'Virtual & Upright'}});
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
